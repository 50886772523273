@import "../../styles/main.scss";
.header-avatar {
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
  font-size: 12px;

  .empresa {
    display: block;
  }
  .cnpj {
    display: block;
  }
  a {
    color: rgba(255, 255, 255, 0.7);
  }
}
