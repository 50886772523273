.app-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .main-content {
    flex: 1 0 auto;
  }
}
