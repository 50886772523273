@import "../../styles/main.scss";

.main-header {
  background: $blue-2;
  padding: $spacing-5;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: $spacing-4;

  .logo {
    width: 100px;
  }

  .content-left {
    flex: 1;
  }

  .content-right {
    flex: 1;
  }
}
