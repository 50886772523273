@import '../main.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  font-weight: 300;
  font-family: "source_sans_pro", sans-serif;
}

h1 {
  margin-bottom: $spacing-3;
}
h2,
h3 {
  margin-bottom: $spacing-4;
}
h4,
h5,
h6 {
  margin-bottom: $spacing-5;
}