@font-face {
  font-family: "source_sans_pro";
  src: url("/fonts/SourceSansPro/sourcesanspro-light-webfont.woff2")
      format("woff2"),
    url("/fonts/SourceSansPro/sourcesanspro-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "source_sans_pro";
  src: url("/fonts/SourceSansPro/sourcesanspro-regular-webfont.woff2")
      format("woff2"),
    url("/fonts/SourceSansPro/sourcesanspro-regular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
