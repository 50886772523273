@import "./styles/main.scss";
@import './styles/global/h.scss';

body {
  margin: 0px;
  padding: 0px;
  background: $grey-1;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}


.wrap-word{
  overflow-wrap: break-word;
  word-break: break-word;
}
