@import "../../styles/main.scss";

.login {
  h1 {
    margin-bottom: $spacing-4;
  }

  &.centralized {
    background: $white;
    padding: $spacing-1;
    margin: 0 auto;
    width: 182px;
    text-align: center;
  }

  .error-message {
    background-color: #990000;
    color: #FFF;
    font-weight: bold;
  }
}
