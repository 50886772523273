$grey-1: #efefef;
$grey-2: #cecece;
$grey-3: #999999;

$white: #ffffff;

$blue-1: #307089;
$blue-2: #225061;

$pink-1: #fe4365;
