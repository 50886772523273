@import "../../styles/main.scss";

.private-app-container {
  display: flex;
  .content-left {
    flex: 0 0 150px;
  }
  .content-right {
    flex: 1;
    padding: $spacing-1;
  }
}
